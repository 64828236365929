import React from "react"
import PropTypes from "prop-types"
import Layout from '../components/Layout'
import { Row}  from 'react-bootstrap'
import * as styles from '../styles/list-item.module.css'

import ArticleMiniPreview from '../components/ArticleMiniPreview'
import Seo from '../components/SEO'

// Components
import { graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout>
      <Seo
          title={tag}
          description={tagHeader}
      /> 
      <h1>{tag}</h1>
      <Row className={styles.list}>
        {edges.map(({ node }) => {
          const { slug, title } = node.frontmatter
          return (
              <ArticleMiniPreview key={node.id}
                   title={title}
                   link = {"/" + slug}
                />  
          )
        })}
      </Row>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___published], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            slug
            title
          }
          id
        }
      }
    }
  }
`